import React from 'react';
import Account from '../../../components/Account';
import PrivateRoute from '../../../components/PrivateRoute';
import ContextProvider from '../../../components/ContextProvider';

const AccountPageEnAu = () => (
  <ContextProvider localeCode="en-AU">
    <PrivateRoute path="/en-AU/account" component={Account} />
  </ContextProvider>
);

export default AccountPageEnAu;
